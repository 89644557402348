<template>
  <div>
    <b-container fluid class="content leftmenu">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row>
        <b-col>
          <b-card v-if="cur_left_menu">
            <el-table
              ref="multipleTable"
              stripe
              class="table-responsive table"
              header-row-class-name="thead-light"
              :data="cur_left_menu"
              @select="handleOneSelectionChange"
              @select-all="handleAllSelectionChange"
            >
              <el-table-column type="selection" width="55" prop="Status"> </el-table-column>
              <el-table-column label="Name">
                <template slot-scope="scope">
                  <div v-if="scope.row.id % 100">
                    {{ scope.row.Name1 }} - {{ scope.row.Name2 }}
                  </div>
                  <div v-else>{{ scope.row.Name1 }}</div>
                </template>
              </el-table-column>
            </el-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Row, Col } from "element-ui";
// import InputAutocomplete from "../InputAutocomplete";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    // InputAutocomplete,
  },
  data() {
    return {
      new_left_menu: [
        { id: 100, Status: false, Name1: "Store", Name2: "Store" },
        { id: 101, Status: false, Name1: "Store", Name2: "Store" },
        { id: 102, Status: false, Name1: "Store", Name2: "Table" },
        { id: 103, Status: false, Name1: "Store", Name2: "Menu" },
        { id: 104, Status: false, Name1: "Store", Name2: "Soldout" },
        { id: 200, Status: false, Name1: "Setup", Name2: "Setup" },
        { id: 201, Status: false, Name1: "Setup", Name2: "Tax" },
        { id: 202, Status: false, Name1: "Setup", Name2: "POS Setting" },
        { id: 203, Status: false, Name1: "Setup", Name2: "Printer" },
        { id: 204, Status: false, Name1: "Setup", Name2: "Payment Setting" },
        { id: 205, Status: false, Name1: "Setup", Name2: "Staff" },
        { id: 206, Status: false, Name1: "Setup", Name2: "Import / Export" },
        { id: 207, Status: false, Name1: "Setup", Name2: "Happy Hour" },
        { id: 208, Status: false, Name1: "Setup", Name2: "Delivery" },
        { id: 209, Status: false, Name1: "Setup", Name2: "Quick Notes" },
        { id: 210, Status: false, Name1: "Setup", Name2: "In Out Notes" },
        { id: 211, Status: false, Name1: "Setup", Name2: "Cloud Store" },
        { id: 300, Status: false, Name1: "Product", Name2: "Product" },
        { id: 301, Status: false, Name1: "Product", Name2: "Department" },
        { id: 302, Status: false, Name1: "Product", Name2: "Category" },
        { id: 303, Status: false, Name1: "Product", Name2: "Modifier" },
        { id: 304, Status: false, Name1: "Product", Name2: "Product" },
        { id: 305, Status: false, Name1: "Product", Name2: "BOM" },
        { id: 400, Status: false, Name1: "VIP", Name2: "VIP" },
        { id: 401, Status: false, Name1: "VIP", Name2: "VIP" },
        { id: 402, Status: false, Name1: "VIP", Name2: "VIP Setting" },
        { id: 500, Status: false, Name1: "Sale", Name2: "Sale" },
        { id: 501, Status: false, Name1: "Sale", Name2: "Customer" },
        { id: 502, Status: false, Name1: "Sale", Name2: "SO" },
        { id: 600, Status: false, Name1: "Purchase", Name2: "Purchase" },
        { id: 601, Status: false, Name1: "Purchase", Name2: "Vendor" },
        { id: 602, Status: false, Name1: "Purchase", Name2: "PO" },
        { id: 700, Status: false, Name1: "Inventory", Name2: "Inventory" },
        { id: 701, Status: false, Name1: "Inventory", Name2: "Inventory" },
        { id: 702, Status: false, Name1: "Inventory", Name2: "Receiving" },
        { id: 703, Status: false, Name1: "Inventory", Name2: "Shipping" },
        { id: 704, Status: false, Name1: "Inventory", Name2: "Inbound" },
        { id: 705, Status: false, Name1: "Inventory", Name2: "Outbound" },
        { id: 706, Status: false, Name1: "Inventory", Name2: "Stock Take" },
        { id: 707, Status: false, Name1: "Inventory", Name2: "Management" },
        { id: 708, Status: false, Name1: "Inventory", Name2: "Transfer" },
        { id: 800, Status: false, Name1: "Report", Name2: "Report" },
        { id: 801, Status: false, Name1: "Report", Name2: "Day End Report" },
        { id: 802, Status: false, Name1: "Report", Name2: "Attendance Report" },
        { id: 803, Status: false, Name1: "Report", Name2: "Sales By Category" },
        { id: 804, Status: false, Name1: "Report", Name2: "Sales By Product" },
        { id: 805, Status: false, Name1: "Report", Name2: "VIP Sales" },
        { id: 806, Status: false, Name1: "Report", Name2: "VIP Reload" },
        { id: 807, Status: false, Name1: "Report", Name2: "POS Sales Report By Category" },
        { id: 808, Status: false, Name1: "Report", Name2: "POS Transaction" },
        { id: 809, Status: false, Name1: "Report", Name2: "Transaction Note" },
        { id: 810, Status: false, Name1: "Report", Name2: "Purchase Summary" },
        { id: 811, Status: false, Name1: "Report", Name2: "Purchase Item" },
        { id: 812, Status: false, Name1: "Report", Name2: "Inventory Summary" },
        { id: 813, Status: false, Name1: "Report", Name2: "Modifier Report" },
        { id: 814, Status: false, Name1: "Report", Name2: "Hourly Sales Report" },
        { id: 815, Status: false, Name1: "Report", Name2: "Cashier Tips Report" },
      ],
      cur_left_menu: null,
      left_menu: null,
      ison: 0,

      delay_timer: null,

      dismessage: "",
      countdown: 0,

      today: "",

      token: "",
      user_id: 0,
    };
  },
  methods: {
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    handleOneSelectionChange(val, row) {
      // console.log("LeftMenu.vue handleOneSelectionChange O", val, row);
      let set = 0;  // Unset first
      if (val.length > 0) {
        let r = val.findIndex((m)=>m.id == row.id);
        if (r >= 0) {
          set = 1;
        }
      }
      for (var i = 0; i < this.left_menu.length; i++) {
        if (this.left_menu[i].id == row.id) {
          this.left_menu[i].Status = set;
        }
      }
      this.form_submit_delay();
    },
    handleAllSelectionChange(val) {
      // console.log("LeftMenu.vue handleAllSelectionChange A", val);
      let set = 0;  // Unset first
      if (val.length > 0) {
        set = 1;
      }
      for (var i = 0; i < this.left_menu.length; i++) {
        this.left_menu[i].Status = set;
      }
      this.form_submit_delay();
    },
    // handleSelectionChange(val) {
      // console.log("LeftMenu.vue handleSelectionChange Start", val);
      // for (var i = 0; i < val.length; i++) {
      //   this.left_menu.find((m) => (m.id == val[i].id)).Status = val[i].Status?1:0;
      // }
      // console.log("LeftMenu.vue handleSelectionChange End", this.left_menu);
      // if (this.ison) {
      //   this.form_submit_delay();
      // }
    // },
    add_menu() {
      for (var i = 0; i < this.left_menu.length; i++) {
        this.cur_left_menu.find((m) => (m.id == this.left_menu[i].id)).Status = (this.left_menu[i].Status == 1);
      }
      for (var i = 0; i < this.cur_left_menu.length; i++) {
        this.$refs.multipleTable.toggleRowSelection(this.cur_left_menu[i], this.cur_left_menu[i].Status);
      }
      // console.log("LeftMenu.vue add_menu", JSON.parse(JSON.stringify(this.cur_left_menu)));
    },
    form_submit_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.form_submit();
      }, 800);
    },
    async form_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LeftMenu", JSON.stringify(this.left_menu));
      // console.log("LeftMenu.vue form_submit", JSON.parse(JSON.stringify(this.left_menu)));

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Left_menu/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 2;
              if (rt.data.left_menu && rt.data.left_menu.Menu) {
                that.left_menu = JSON.parse(rt.data.left_menu.Menu);
                // console.log("LeftMenu.vue form_submit R", JSON.parse(JSON.stringify(that.left_menu)));
              }
              that.add_menu();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.cur_left_menu = JSON.parse(JSON.stringify(this.new_left_menu));
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/left_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.left_menu && rt.data.left_menu.Menu) {
                that.left_menu = JSON.parse(rt.data.left_menu.Menu);
              }
              that.add_menu();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log("LeftMenu.vue init_data catch ",error);
          that.$router.push("/login");
        });
    },
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    var today = new Date();
    this.today =
      today.getFullYear() +
      "-" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(today.getDate()).padStart(2, "0");

    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    return this.init_data();
  },
};
</script>
<style>
.content {
  padding-top: 3rem;
}
.show_alert {
  position: absolute;
  top: 3rem;
  width: 100%;
  z-index: 100;
}
.leftmenu .card .table td {
  padding: 0rem 0.5rem;
}
</style>
